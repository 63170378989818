"use client"

import Version from "@/components/ui/version/Version"
import { useModalContext } from "@/contexts/ModalContext"
import { isDevelopmentMode } from "@/utils/environmentUtils"
import { getAssetsPath } from "@/utils/getAssetsPathUtils"
import { Box, Button, ButtonProps, Divider, Grid, Stack, styled } from "@mui/material"
import Image from "next/image"
import { Suspense, lazy, useState } from "react"

const PrivacyModal = lazy(() =>
  import("@/containers/privacy/PoliciesModals").then((module) => ({
    default: module.PrivacyModal,
  })),
)
const TermsModal = lazy(() =>
  import("@/containers/privacy/PoliciesModals").then((module) => ({
    default: module.TermsModal,
  })),
)
const AccessModal = lazy(() => import("../person/login/AccessModal"))

const FooterButton = styled(Button)<ButtonProps>(({ variant }) => ({
  textTransform: "none",
  color: "inherit",
  borderColor: "inherit",
  ...(variant === undefined && {
    variant: "text",
  }),
}))

export default function Footer() {
  const [openTerms, setOpenTerms] = useState(false)
  const [openPrivacy, setOpenPrivacy] = useState(false)

  const { openModal } = useModalContext()

  return (
    <Box
      component="footer"
      color="white"
      alignContent={"center"}
      justifyContent={"center"}
      display={"flex"}
      sx={{
        backgroundColor: "blueMsGov.main",
        pt: 1,
        width: "100%",
      }}
    >
      <Grid
        container
        rowSpacing={1}
        columnSpacing={2}
        sx={{
          justifyContent: "center",
          alignItems: "center",
          maxWidth: "lg",
        }}
      >
        <Grid
          item
          xs={6}
          md={3}
          order={{ xs: 2, md: 1 }}
          sx={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: {
                xs: "140px",
                sm: "150px",
                md: "120px",
              },
              height: "auto",
              marginRight: {
                xs: 2,
                md: 0,
              },
            }}
          >
            <Image
              src={getAssetsPath() + "/img/logo-ms-qualifica-branco.svg"}
              alt="logo do ms qualifica"
              width={0}
              height={0}
              style={{ width: "100%", height: "100%" }}
            />
          </Box>
        </Grid>

        <Grid item xs={12} md={3} order={{ xs: 1, md: 2 }}>
          <Stack
            sx={{
              display: "flex",
              alignItems: "center",

              mt: "5px",
            }}
          >
            {isDevelopmentMode() && (
              <FooterButton
                onClick={() => openModal("accessModal")}
                variant="outlined"
                id="btn-open-login-modal"
              >
                ENTRE OU CADASTRE-SE
              </FooterButton>
            )}

            <FooterButton
              onClick={() => (window.location.href = "mailto:msqualifica@ms.gov.br")}
            >
              Contato e Ajuda
            </FooterButton>
          </Stack>
        </Grid>

        <Grid item xs={12} md={3} order={{ xs: 4, md: 3 }}>
          <Stack
            spacing={1}
            direction="row"
            sx={{ alignItems: "center", justifyContent: "center" }}
          >
            <FooterButton onClick={() => setOpenTerms(true)}>Termos de uso</FooterButton>
            <Divider
              orientation="vertical"
              variant="fullWidth"
              flexItem
              aria-hidden="true"
              sx={{ backgroundColor: "white" }}
            />
            <FooterButton onClick={() => setOpenPrivacy(true)}>
              Política de privacidade
            </FooterButton>
          </Stack>
        </Grid>

        <Grid
          item
          xs={6}
          md={3}
          order={{ xs: 3, md: 3 }}
          sx={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
          }}
        >
          <Stack
            sx={{
              display: "flex",
              alignItems: "center",
              rowGap: "0.5rem",
              pl: { xs: 0, md: 10 },
            }}
          >
            <Box
              sx={{
                width: {
                  xs: "120px",
                  sm: "130px",
                  md: "100px",
                },
                height: "auto",
                marginLeft: {
                  xs: 2,
                  md: 0,
                },
                display: "flex",
                alignItems: "center",
              }}
            >
              <Image
                src={getAssetsPath() + "/img/logo-governo-ms-branco.svg"}
                alt="logo do governo de Mato Grosso do Sul"
                width={0}
                height={0}
                style={{ width: "100%", height: "100%" }}
              />
            </Box>
            <Version prefix="Versão:" />
          </Stack>
        </Grid>
      </Grid>

      <Suspense fallback={<div>Carregando...</div>}>
        {isDevelopmentMode() && <AccessModal />}
        <TermsModal isOpen={openTerms} setIsOpen={setOpenTerms} />
        <PrivacyModal isOpen={openPrivacy} setIsOpen={setOpenPrivacy} />
      </Suspense>
    </Box>
  )
}
