/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * ModalContext.tsx
 * @description Modal context for managing modals on the application.
 * @note This context is used to manage modals in the application,
 * the any type is used to store any data that can be passed to the modal.
 * In decorrent of the use of any type, eslint is disabled for this file.
 */
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react"

export enum ModalActionType {
  CREATE,
  UPDATE,
  DUPLICATE,
}

interface ModalData {
  [key: string]: {
    isOpen: boolean
    data?: any
    actionType?: ModalActionType
  }
}
interface ModalContextInterface {
  modals: ModalData
  openModal: (modalName: string, data?: any) => void
  closeModal: (modalName: string) => void
  toggleModal: (modalName: string) => void
  getModalData: (modalName: string) => any
  setModalData: (modalName: string, data: any) => void
  isModalOpen: (modalName: string) => boolean
  getModalActionType: (modalName: string) => ModalActionType | undefined
  resetModalData: (modalName: string) => void
  openCreateModal: (modalName: string, data?: any) => void
  openUpdateModal: (modalName: string, id: string, data?: any) => void
  openDuplicateModal: (modalName: string, id: string, data?: any) => void
}

const ModalContext = createContext<ModalContextInterface | null>(null)

export function ModalProvider({ children }: Readonly<{ children: ReactNode }>) {
  const [modals, setModals] = useState<ModalData>({})

  const openModal = useCallback(
    (modalName: string, data?: any, actionType?: ModalActionType) => {
      setModals((prev) => ({
        ...prev,
        [modalName]: {
          isOpen: true,
          data: { ...prev[modalName]?.data, ...data },
          actionType,
        },
      }))
    },
    [],
  )

  const closeModal = useCallback((modalName: string) => {
    setModals((prev) => ({
      ...prev,
      [modalName]: { isOpen: false, data: prev[modalName]?.data },
    }))
  }, [])

  const toggleModal = useCallback((modalName: string) => {
    setModals((prev) => ({
      ...prev,
      [modalName]: {
        isOpen: !prev[modalName]?.isOpen,
        data: prev[modalName]?.data,
      },
    }))
  }, [])

  const getModalData = useCallback(
    (modalName: string) => modals[modalName]?.data ?? {},
    [modals],
  )

  const setModalData = useCallback(
    (modalName: string, newData: any, actionType?: ModalActionType) => {
      setModals((prev) => ({
        ...prev,
        [modalName]: {
          isOpen: prev[modalName]?.isOpen || false,
          data: { ...prev[modalName]?.data, ...newData },
          actionType: actionType ?? prev[modalName]?.actionType,
        },
      }))
    },
    [],
  )

  const isModalOpen = useCallback(
    (modalName: string) => !!modals[modalName]?.isOpen,
    [modals],
  )

  const getModalActionType = useCallback(
    (modalName: string) => modals[modalName]?.actionType,
    [modals],
  )

  const resetModalData = useCallback((modalName: string) => {
    setModals((prev) => ({
      ...prev,
      [modalName]: {
        isOpen: prev[modalName]?.isOpen || false,
        data: undefined,
        actionType: undefined,
      },
    }))
  }, [])

  const openCreateModal = useCallback(
    (modalName: string, data?: any) => {
      openModal(modalName, data, ModalActionType.CREATE)
    },
    [openModal],
  )

  const openUpdateModal = useCallback((modalName: string, id: string, data?: any) => {
    setModals((prev) => ({
      ...prev,
      [modalName]: {
        isOpen: true,
        data: { ...prev[modalName]?.data, id, ...data },
        actionType: ModalActionType.UPDATE,
      },
    }))
  }, [])

  const openDuplicateModal = useCallback(
    (modalName: string, id: string, data?: any) => {
      openModal(modalName, { id, ...data }, ModalActionType.DUPLICATE)
    },
    [openModal],
  )

  const modalContextValue = useMemo(
    () => ({
      modals,
      openModal,
      closeModal,
      toggleModal,
      getModalData,
      setModalData,
      isModalOpen,
      getModalActionType,
      resetModalData,
      openCreateModal,
      openUpdateModal,
      openDuplicateModal,
    }),
    //eslint-disable-next-line
    [modals],
  )

  return (
    <ModalContext.Provider value={modalContextValue}>{children}</ModalContext.Provider>
  )
}

export function useModalContext() {
  const context = useContext(ModalContext)

  if (!context) {
    throw new Error("useModalContext must be used within a ModalProvider")
  }

  return context
}
